<template>
  <div class=" p-2" style="min-height: 200px">
    <div class="table-responsive border-b border-l border-r">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Txn Type</th>
          <th>Voucher No</th>
          <th>Line Description</th>
          <th>Account</th>
          <th>Debit</th>
          <th>Credit</th>
          <th>Balance</th>
        </tr>
        </thead>

        <tbody>
        <tr v-if="currentPage === 1 || ((currentPage == undefined) && (currentPage === lastPage))">
          <td>  </td>
          <td>  </td>
          <td>  </td>
          <td>  </td>
          <td> Opening Balance </td>
          <td class="text-end">  </td>
          <td class="text-end">  </td>
          <td class="text-end"> {{ openingBalance ? commaFormat(openingBalance) : 0 }} </td>
        </tr>
        <tr v-for="(item, i) in ledgers" :key="i">
          <td>
            <span class="fw-bold">{{ item.date }}</span>
          </td>
          <td> {{ item.txn_type }} </td>
          <td> {{ item.voucher_no }} </td>
          <td> {{ item.line_description }} </td>
          <td>
              <span  v-if="((item.debit == null || item.debit === 0) && item.debit_account_head_count > 1)
                     || ((item.credit == null || item.credit === 0) && item.credit_account_head_count > 1)"
                     class="cursor-pointer"
                     style="color: deepskyblue;"
                     @click="seeDetails(item.ledger_master_id, item.txn_type)"
              >
                See Details
              </span>
              <span v-else>
                <span v-if="(item.debit == null || item.debit === 0)">
                    {{ item.debit_account_head }}
                </span>
                 <span v-if="(item.credit == null || item.credit === 0)">
                    {{ item.credit_account_head }}
                </span>
              </span>
          </td>
          <td class="text-end"> {{ commaFormat(item.debit) }} </td>
          <td class="text-end"> {{ commaFormat(item.credit) }} </td>
          <td class="text-end"> {{ commaFormat(item.balance) }} </td>
        </tr>
        <tr v-if="currentPage === lastPage">
          <td>  </td>
          <td>  </td>
          <td>  </td>
          <td>  </td>
          <td> Closing Balance </td>
          <td class="text-end"> {{ totalDebitBalance ? commaFormat(totalDebitBalance) : 0 }}</td>
          <td class="text-end"> {{ totalCreditBalance ? commaFormat(totalCreditBalance) : 0 }}  </td>
          <td class="text-end"> {{ closingBalance ? commaFormat(closingBalance) : 0 }}</td>
        </tr>

        </tbody>
      </table>
      <!-- <p v-if="!ledgers.length" class="mt-5 text-center">No data</p> -->
    </div>
  </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'
export default {
  name: 'LedgerTable',
  props: {
    openingBalance: Number,
    closingBalance: Number,
    totalDebitBalance: Number,
    totalCreditBalance: Number,
    currentPage: Number,
    lastPage: Number,
    ledgers: {
      type: Array
    }
  },
  computed: {
  },
  methods: {
    seeDetails(id, txnType) {
      let name = "";
      let allParams = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
      };

      switch (txnType){
        case "general_journal" :
          name = 'EditJournal';
          allParams.journalId = id;
          break;
        case "bill_journal" :
          name = 'purchase-bill-edit';
          allParams.purchaseId = id;
          break;
        case "invoice_journal" :
          name = 'sales-invoice-edit';
          allParams.salesId = id;
          break;
        case "adjustment_journal" :
          name = 'EditJournal';
          allParams.journalId = id;
          break;
        case "correction_journal" :
          name = 'EditJournal';
          allParams.journalId = id;
          break;
        case "general_receipt" :
          name = 'EditReceipt';
          allParams.receiptId = id;
          break;
        case "invoice_receipt" :
          name = 'money-receipt-edit';
          allParams.receiptId = id;
          break;
        case "general_payment" :
          name = 'EditPayment';
          allParams.paymentId = id;
          break;
        case "bill_payment" :
          name = 'pay-bill-edit';
          allParams.paymentId = id;
          break;
        case "general_contra" :
          name = 'EditContra';
          allParams.contraId = id;
          break;
      }

      this.$router.push({name: name,
        params: allParams,
        query: {
          start: this.$route.query.start,
          end:  this.$route.query.end
        }
      })
    }
  },
  setup() {
    const {commaFormat} = figureFormatter ()

    return {
      commaFormat
    }
  },
}
</script>
